import React, { Component } from 'react';
import { connect } from "react-redux";
import { Select, Button, message, Switch, Space, Card, Typography } from 'antd';
import { AddressBookIcon } from "hugeicons-react";
import './Extracts.css';

import {
	extractIncompleteSignUps,
	extractPaidCompleteSignUps,
	extractPaidAccounts,
	extractAllPaymentAttempts,
	extractUnPaidCompleteSignUps,
	extractCompleteSignUps,
	extractLastLogins,
	extractAllSignUps
} from "../../../actions/SettingsActions";

const { Title } = Typography;
const { Option } = Select;

class UserDataExtractor extends Component {
	state = {
		selectedOption: null,
		email: false,
		plus: false,
		loading: this.props.loading
	};

	componentWillReceiveProps(nextProps) {
		if (this.props !== nextProps) {
			this.setState({ loading: nextProps.loading });
		}
	}

	handleSelectionChange = (value) => {
		this.setState({ selectedOption: value });
	}

	extractData = () => {
		const { selectedOption } = this.state;
		if (!selectedOption) {
			message.info('Please select an option.');
			return;
		}

		const handlers = {
			incompleteSignUps: this.handleIncompleteSignUps,
			paidCompleteSignUps: this.handlePaidCompleteSignUps,
			paidAccounts: this.handlePaidAccounts,
			paymentAttempts: this.handlePaymentAttempts,
			unpaidCompleteSignUps: this.handleUnpaidCompleteSignUps,
			allCompleteSignUps: this.handleAllCompleteSignUps,
			inactiveUsers: this.handleInactiveUsers,
			allUsers: this.handleAllUsers
		};

		handlers[selectedOption]?.();
	}

	handleIncompleteSignUps = () => {
		const { email, plus } = this.state;
		message.loading('Extracting Incomplete SignUps...');
		this.props.extractIncompleteSignUps({ email, plus });
	}

	handlePaidCompleteSignUps = () => {
		const { email, plus } = this.state;
		message.loading('Extracting Paid Complete SignUps...');
		this.props.extractPaidCompleteSignUps({ email, plus });
	}

	handlePaidAccounts = () => {
		const { email, plus } = this.state;
		message.loading('Extracting Paid Accounts...');
		this.props.extractPaidAccounts({ email, plus });
	}

	handlePaymentAttempts = () => {
		const { email, plus } = this.state;
		message.loading('Extracting Payment Attempts...');
		this.props.extractAllPaymentAttempts({ email, plus });
	}

	handleUnpaidCompleteSignUps = () => {
		const { email, plus } = this.state;
		message.loading('Extracting Unpaid Complete SignUps...');
		this.props.extractUnPaidCompleteSignUps({ email, plus });
	}

	handleAllCompleteSignUps = () => {
		const { email, plus } = this.state;
		message.loading('Extracting All Complete SignUps...');
		this.props.extractCompleteSignUps({ email, plus });
	}

	handleInactiveUsers = () => {
		const { email, plus } = this.state;
		message.loading('Extracting Inactive Users...');
		this.props.extractLastLogins({ email, plus });
	}

	handleAllUsers = () => {
		const { email, plus } = this.state;
		message.loading('Extracting All Users...');
		this.props.extractAllSignUps({ email, plus });
	}

	render() {
		const { loading, email, plus, selectedOption } = this.state;

		return (
			<div className="nafasi-extracts__container">
				<div className="nafasi-extracts">
					<Space direction="vertical" style={{ width: '100%' }} size="middle">
						<Card size="small" className="nafasi-extracts__header-card">
							<div className="nafasi-extracts__header">
								<AddressBookIcon size={24} className="nafasi-extracts__icon" />
								<Title level={4} className="nafasi-extracts__title">Data Extracts</Title>
							</div>
						</Card>

						<Card className="nafasi-extracts__content">
							<Space direction="vertical" size="large" className="nafasi-extracts__form">
								<div className="nafasi-extracts__select-container">
									<Select
										className="nafasi-extracts__select"
										placeholder="Select a user group"
										onChange={this.handleSelectionChange}
										value={selectedOption}
									>
										<Option value="incompleteSignUps">Incomplete SignUps</Option>
										<Option value="paidCompleteSignUps">Paid Complete SignUps</Option>
										<Option value="paidAccounts">Paid Accounts</Option>
										<Option value="paymentAttempts">All Payment Attempts</Option>
										<Option value="unpaidCompleteSignUps">Unpaid Complete SignUps</Option>
										<Option value="allCompleteSignUps">All Complete SignUps</Option>
										<Option value="inactiveUsers">Inactive Users</Option>
										<Option value="allUsers">All Users</Option>
									</Select>
								</div>

								<div className="nafasi-extracts__switches">
									<div className="nafasi-extracts__switch-item">
										<Switch
											checked={email}
											onChange={() => this.setState({ email: !email })}
											className="nafasi-extracts__switch"
										/>
										<span className="nafasi-extracts__switch-label">
											{email ? 'Email' : 'Phone'}
										</span>
									</div>

									<div className="nafasi-extracts__switch-item">
										<Switch
											checked={plus}
											onChange={() => this.setState({ plus: !plus })}
											className="nafasi-extracts__switch"
										/>
										<span className="nafasi-extracts__switch-label">
											{plus ? 'Plus' : 'No Plus'}
										</span>
									</div>
								</div>

								<div className="nafasi-extracts__actions">
									<Button
										type="primary"
										onClick={this.extractData}
										loading={loading}
										className="nafasi-extracts__button"
										icon={<AddressBookIcon size={16} />}
									>
										Extract Data
									</Button>
								</div>
							</Space>
						</Card>
					</Space>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	loading: state.settings.loading
});

export default connect(mapStateToProps, {
	extractIncompleteSignUps,
	extractPaidCompleteSignUps,
	extractPaidAccounts,
	extractAllPaymentAttempts,
	extractUnPaidCompleteSignUps,
	extractCompleteSignUps,
	extractLastLogins,
	extractAllSignUps
})(UserDataExtractor);
