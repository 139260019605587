import firebase from "firebase";
import moment from "moment";
import {
    DELETE_COMPANY_ASSETS,
    DELETE_COMPANY_ASSETS_FAILED,
    DELETE_COMPANY_ASSETS_SUCCESSFUL,

    ACCOUNT_INFO_CHANGED,
    ASSETS_DETAILS_CHANGED,

    FETCH_COMPANY_ASSETS,
    FETCH_COMPANY_ASSETS_FAILED,
    FETCH_COMPANY_ASSETS_SUCCESSFUL,
    HOLIDAY_DETAILS_CHANGED,

    FETCH_TERMS_AND_CONDITIONS,
    FETCH_TERMS_AND_CONDITIONS_SUCCESSFUL,
    FETCH_TERMS_AND_CONDITIONS_FAILED,
    SAVE_TERMS_AND_CONDITIONS,
    SAVE_TERMS_AND_CONDITIONS_SUCCESSFUL,
    SAVE_TERMS_AND_CONDITIONS_FAILED,
    TERMS_AND_CONDITIONS_CHANGED,

    CLEAR_TERMS_AND_CONDITIONS_MESSAGES,
    SAVE_COMPANY_ASSETS,
    SAVE_COMPANY_ASSETS_FAILED,
    SAVE_COMPANY_ASSETS_SUCCESSFUL,
    SELECTED_TRAINEES_CHANGED,
    SELECTED_TRAINING,
    TRAINING_DETAILS_CHANGED,
    TRAINING_EMPLOYEE_DETAILS_CHANGED,
    SETTINGS_FIELD_CHANGED,
    CLEAR_COMPANY_ASSET_MESSAGE,
    SAVE_PROMO_CODE_FAILED,
    SAVE_PROMO_CODE,
    SAVE_PROMO_CODE_SUCCESSFUL,
    SAVE_BLOG,
    SAVE_BLOG_SUCCESSFUL,
    SAVE_BLOG_FAILED,
    SAVE_NOTIFICATIONS_FAILED,
    SAVE_NOTIFICATIONS,
    SAVE_NOTIFICATIONS_SUCCESSFUL,
    EXTRACT_DATA,
    EXTRACT_DATA_SUCCESSFUL,
    EXTRACT_DATA_FAILED,
    FETCH_FEEDBACK,
    FETCH_FEEDBACK_FAILED,
    FETCH_FEEDBACK_SUCCESSFUL,
    FETCH_ASSESSMENT,
    FETCH_ASSESSMENT_SUCCESSFUL,
    FETCH_ASSESSMENT_FAILED,
    FETCH_RECRUITERS_REQUESTS,
    FETCH_RECRUITERS_REQUESTS_SUCCESSFUL,
    FETCH_RECRUITERS_REQUESTS_FAILED,
} from "./Types";
import {message} from "antd";

//-> Message Alert Icons

//property declarations
const XLSX = require('xlsx');


//------------------------------------------------------------- ACCOUNT INFO ---------------------------------------------------------------
export const accountInfoChanged = ({prop, value}) => {
    return {
        type: ACCOUNT_INFO_CHANGED,
        payload: {prop, value}
    }
};

//------------------------------------------------------------- TERMS AND CONDITIONS ---------------------------------------------------------------


export const termsAndConditionsChanged = ({prop, value}) => {
    return {
        type: TERMS_AND_CONDITIONS_CHANGED,
        payload: {prop, value}
    }
};

export const saveTermsAndConditions = ({ docFile, docTitle }) => {

    return(dispatch) => {
        dispatch({ type: SAVE_TERMS_AND_CONDITIONS });

        const docFileID = Math.random().toString(36).substring(6).toUpperCase();

        const uploadTask = firebase.storage().ref(`termsAndConditions/${docFileID}`)
            .put(docFile);

        const termsUpdateTime = firebase.firestore.FieldValue.serverTimestamp();


        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
            function(snapshot) {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                        console.log('Upload is paused');
                        break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                        console.log('Upload is running');
                        break;
                    default:
                }
            }, function(error) {

                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        break;

                    case 'storage/canceled':
                        // User canceled the upload
                        break;

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        break;
                    default:
                }
            }, function() {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {

                    //post downloadURL and file name to an object in database
                    firebase.firestore().collection('legal').doc(docFileID)
                        .set({ docTitle, docFileID,  docFile: downloadURL, termsUpdateTime }, { merge: true })
                        .then(() => {
                            dispatch({ type: SAVE_TERMS_AND_CONDITIONS_SUCCESSFUL });
                            //successfully posted image details to database
                            dispatch({ type: CLEAR_TERMS_AND_CONDITIONS_MESSAGES });

                        }).catch((error) => {
                        dispatch({ type: SAVE_TERMS_AND_CONDITIONS_FAILED })
                        console.log(`there was an error saving the terms and conditions document`);
                        console.log(error);

                    });
                });
            });
    };
};

export const fetchTermsAndConditions = () => {

    return(dispatch) => {
        dispatch({ type: FETCH_TERMS_AND_CONDITIONS });

        firebase.firestore().collection("legal")
            .orderBy("termsUpdateTime", "desc")
            .onSnapshot(snapshot => {

                if(snapshot.size === 0) {

                } else {
                    let fetchedTermsAndConditions = {};
                    snapshot.forEach(function(doc) {
                        fetchedTermsAndConditions[doc.id] = doc.data();
                    });

                    dispatch({
                        type: FETCH_TERMS_AND_CONDITIONS_SUCCESSFUL,
                        payload: fetchedTermsAndConditions
                    });
                }

            }, function (error) {
                console.log("Error fetching terms and conditions document");
                console.log(error);
                dispatch({ type: FETCH_TERMS_AND_CONDITIONS_FAILED })
            });
    }
};

//------------------------------------------------------------- TRAINING ---------------------------------------------------------------

export const trainingDetailsChanged = ({prop, value}) => {
    return {
        type: TRAINING_DETAILS_CHANGED,
        payload: {prop, value}
    }
};

export const holidayDetailsChanged = ({prop, value}) => {
    return {
        type: HOLIDAY_DETAILS_CHANGED,
        payload: {prop, value}
    }
};

export const trainingEmployeeDetailsChanged = ({prop, value}) => {
    return {
        type: TRAINING_EMPLOYEE_DETAILS_CHANGED,
        payload: {prop, value}
    }
};

export const assetDetailsChanged = ({prop, value}) => {
    return {
        type: ASSETS_DETAILS_CHANGED,
        payload: {prop, value}
    }
};

export const selectedTraineesChanged = (checkedValues) => {
    return {
        type: SELECTED_TRAINEES_CHANGED,
        payload: checkedValues
    }
};

export const selectedTraining = (value) => {
    return {
        type: SELECTED_TRAINING,
        payload: value
    }
};



//------------------------------------------------------------- HOLIDAYS ---------------------------------------------------------------






//------------------------------------------------------------- ASSETS ---------------------------------------------------------------
function toCamelCase(str) {
    // Remove leading/trailing white spaces and split the string by spaces
    const words = str.trim().split(' ');

    // Convert the first word to lowercase
    let camelCase = words[0].toLowerCase();

    // Capitalize the first letter of each subsequent word and append it to the camel case string
    for (let i = 1; i < words.length; i++) {
        const capitalized = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
        camelCase += capitalized;
    }

    return camelCase;
}


export const addCompanyAsset = ({ assetCategory, assetTitle }) => {

    //create asset ID
    const assetID = toCamelCase(assetTitle);

    return(dispatch) => {

        dispatch({type: SAVE_COMPANY_ASSETS});

        firebase.firestore().collection("assetLists").doc(assetID)
            .set({ ID: assetID, list: assetCategory, title: assetTitle })
            .then(() => {
                //

                dispatch({ type: SAVE_COMPANY_ASSETS_SUCCESSFUL});
                dispatch({type: CLEAR_COMPANY_ASSET_MESSAGE});

            }).catch((error) => {
            dispatch({ type: SAVE_COMPANY_ASSETS_FAILED });
            console.log(error);
            dispatch({type: CLEAR_COMPANY_ASSET_MESSAGE});
        });
    }
};

export const fetchAssets = () => {

    return(dispatch) => {
        dispatch({ type: FETCH_COMPANY_ASSETS });

        let fetchedCompanyAssets = {};

        firebase.firestore().collection("assetLists").limit(10)
            .onSnapshot(function(querySnapshot) {

                querySnapshot.forEach(function(doc) {
                    fetchedCompanyAssets[doc.id] = doc.data();
                });

                dispatch({
                    type: FETCH_COMPANY_ASSETS_SUCCESSFUL,
                    payload: fetchedCompanyAssets
                });

            }, function (error) {
                console.log("Error fetching company education");
                console.log(error);
                dispatch({ type: FETCH_COMPANY_ASSETS_FAILED })
            });
    }
};

export const deleteCompanyAsset = ({assetID}) => {

    return(dispatch) => {

        dispatch({ type: DELETE_COMPANY_ASSETS });

        firebase.firestore().collection("assetLists").doc(assetID)
            .delete()
            .then(() => {
                dispatch({ type: DELETE_COMPANY_ASSETS_SUCCESSFUL });
                message.success("Successfully Deleted");
                dispatch({type: CLEAR_COMPANY_ASSET_MESSAGE});
            })
            .catch((error) => {
                dispatch({ type: DELETE_COMPANY_ASSETS_FAILED });
                console.log("Error deleting company asset");
                console.log(error);
                dispatch({type: CLEAR_COMPANY_ASSET_MESSAGE});
            })
    }
};

//------------------------------------------------------------- PROMO CODE ---------------------------------------------------------------
export const settingsFieldChanged = ({ prop, value }) => {
    return {
        type: SETTINGS_FIELD_CHANGED,
        payload: { prop, value }
    }
};

export const savePromoCode = ({ affiliateCode, accountName, accountNumber, bankCode, bankName, commissionPercent, discountPercent, email }) => {
    return(dispatch) => {
        dispatch({ type: SAVE_PROMO_CODE });
        //check that promo code does not exists
        firebase.firestore().collection("affiliates").doc(affiliateCode)
            .get()
            .then(doc => {
                if (doc.exists) {
                    dispatch({ type: SAVE_PROMO_CODE_FAILED });
                    message.warn("Promo Code Already Exists");
                } else {
                    //write to affiliate profile
                    firebase.firestore().collection("seekers").where("email", "==", email)
                        .get()
                        .then(snapshot => {
                            if (snapshot.size === 0) {
                                dispatch({ type: SAVE_PROMO_CODE_FAILED });
                                message.warn("Account with given email does not exist");
                            } else {
                                let account;
                                let userID;
                                snapshot.forEach(doc => {
                                    account = doc.data();
                                });

                                if (account) {
                                    if ("userID" in account) {
                                        userID = account.userID;
                                        firebase.firestore().collection("users")
                                            .doc("clients")
                                            .collection(userID)
                                            .doc("public")
                                            .collection("account")
                                            .doc("info")
                                            .update({
                                                affiliate: {
                                                    active: true,
                                                    affiliateCode,
                                                    bank: {
                                                        accountName,
                                                        accountNumber,
                                                        bankCode,
                                                        bankName
                                                    },
                                                    joinDate: new Date()
                                                }
                                            })
                                            .then(() => {
                                                //write to affiliates path
                                                firebase.firestore().collection("affiliates").doc(affiliateCode)
                                                    .set({
                                                        active: true,
                                                        affiliateCode,
                                                        commissionPercent,
                                                        discountPercent,
                                                        ngnAmount: 0,
                                                        ngnCashOut: 0,
                                                        tshAmount: 0,
                                                        tshCashOut: 0,
                                                        usdAmount: 0,
                                                        usdCashOut: 0,
                                                        userID
                                                    })
                                                    .then(() => {
                                                        message.success(`Successfully created Promo Code ${affiliateCode}`);
                                                        dispatch({ type: SAVE_PROMO_CODE_SUCCESSFUL});
                                                    })
                                            })
                                            .catch(e => {
                                                console.log("error saving promo code");
                                                console.log(e);
                                                message.warn("Error updating profile");
                                                dispatch({ type: SAVE_PROMO_CODE_FAILED });
                                            })
                                    } else {
                                        message.warn("User ID not found");
                                        console.log("error saving promo code");
                                        dispatch({ type: SAVE_PROMO_CODE_FAILED });
                                    }
                                } else {
                                    message.warn("Account could not be extracted");
                                    console.log("error saving promo code");
                                    dispatch({ type: SAVE_PROMO_CODE_FAILED });
                                }
                            }
                        })
                        .catch(e => {
                            console.log("error saving promo code");
                            console.log(e);
                            dispatch({ type: SAVE_PROMO_CODE_FAILED });
                        })
                }
            })
            .catch(e => {
                console.log("error saving promo code");
                console.log(e);
                dispatch({ type: SAVE_PROMO_CODE_FAILED });
            })
    }
}


//------------------------------------------------------------- BLOG CODE ---------------------------------------------------------------

export const saveBlog = ({ html, title, description }) => {
    return(dispatch) => {
        dispatch({ type: SAVE_BLOG });
        const blogID = Math.random().toString(36).substring(6).toUpperCase();
        firebase.firestore().collection("blogs").doc(blogID)
            .set({
                blogID,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(), // Save creation time
                content: html,
                title,
                description,
                active: true
            }, { merge: true })
            .then(docRef => {
                // Here, you can show the URL to the user or automatically redirect them
                dispatch({ type: SAVE_BLOG_SUCCESSFUL });
                message.success("Blog Saved");
                firebase.firestore().collection('topics').doc(blogID)
                    .set({
                        topicID: blogID,
                        date: firebase.firestore.FieldValue.serverTimestamp(),
                        title: title,
                        description,
                        url: `https://nafasiio.web.app/blog/${blogID}`,
                        active: true
                    }, { merge: true })
                    .then(() => {
                        const screen = "pdf";
                        const pdfUrl = `https://nafasiio.web.app/blog/${blogID}`;
                        sendGeneralNotification({ notificationTitle: title, notificationMessage: description, screen, pdfUrl, dispatch });
                    }).catch((error) => {
                    console.log(error);
                    dispatch({ type: SAVE_NOTIFICATIONS_FAILED });
                });

            })
            .catch(error => {
                console.error("Error adding document: ", error);
                dispatch({ type: SAVE_BLOG_FAILED });
            });
    }
}

function sendGeneralNotification({ notificationTitle, notificationMessage, screen, pdfUrl, dispatch }) {

    const body = JSON.stringify({ notificationTitle, notificationMessage, screen, pdfUrl });

    dispatch({ type: SAVE_NOTIFICATIONS })
    fetch("https://us-central1-nafasiio.cloudfunctions.net/sendGeneralNotification",  {
        method: 'POST',
        body,
        mode: 'cors',
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            console.log("success");

            dispatch({ type: SAVE_NOTIFICATIONS_SUCCESSFUL });

        }).catch(function(error) {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // // ...
        // console.log(errorCode);
        console.log("notification compute error");
        console.log(error);
        dispatch({ type: SAVE_NOTIFICATIONS_FAILED });
    });
}

//------------------------------------------------------------- DATA EXTRACTION ---------------------------------------------------------------

export const extractIncompleteSignUps = ({ email }) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EXTRACT_DATA });
            const seekersRef = firebase.firestore().collection('seekers');
            const snapshot = await seekersRef.where('complete', '==', false).get();

            if (snapshot.empty) {
                message.info('No seeker is incomplete.');
                dispatch({ type: EXTRACT_DATA_FAILED });
                return;
            }

            let data = [];
            snapshot.forEach(doc => {
                let seeker = doc.data();
                if (email) {
                    // Only push relevant fields to the data array
                    data.push({
                        email: seeker.email || '',
                        first_name: seeker.firstName || '',
                        last_name: seeker.lastName || '',
                        address_line_1: seeker.location && seeker.location.streetAddress ? seeker.location.streetAddress : '',
                        address_line_2: seeker.location && seeker.location.streetAddress ? seeker.location.streetAddress : '',
                        city: seeker.location && seeker.location.region ? seeker.location.region : '',
                        state_province_region: seeker.location && seeker.location.region ? seeker.location.region : '',
                        postal_code: seeker.location && seeker.location.postalCode ? seeker.location.postalCode : '',
                        country: seeker.country || '',
                    });
                } else {
                    // Only push relevant fields to the data array
                    data.push({
                        firstName: seeker.firstName || '',
                        lastName: seeker.lastName || '',
                        email: seeker.email || '',
                        phone: seeker.phone || '',
                        country: seeker.country || '',
                    });
                }
            });

            // Generate workbook and add the sheet
            const ws = XLSX.utils.json_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Seekers');

            // Define file name
            const now = moment().format('DD_MM_YYYY_HH_mm');
            const fileName = `nafasi_incomplete_signups_${now}.xlsx`;

            // Write workbook to file (for Node.js) - adjust the path as needed for your environment
            dispatch({ type: EXTRACT_DATA_SUCCESSFUL });
            XLSX.writeFile(wb, fileName);

            message.success(`Excel file has been created: ${fileName}`);
        } catch (error) {
            message.error("Error fetching documents or generating Excel:", error);
            dispatch({ type: EXTRACT_DATA_FAILED });
        }
    }
}

export const extractPaidCompleteSignUps = ({ email }) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EXTRACT_DATA });
            const seekersRef = firebase.firestore().collection('seekers');
            const snapshot = await seekersRef.where('complete', '==', true).where('packageIndex', '<', 3).get();

            if (snapshot.empty) {
                message.info('No paid and complete seeker.');
                dispatch({ type: EXTRACT_DATA_FAILED });
                return;
            }

            let data = [];
            snapshot.forEach(doc => {
                let seeker = doc.data();
                if (email) {
                    // Only push relevant fields to the data array
                    data.push({
                        email: seeker.email || '',
                        first_name: seeker.firstName || '',
                        last_name: seeker.lastName || '',
                        address_line_1: seeker.location && seeker.location.streetAddress ? seeker.location.streetAddress : '',
                        address_line_2: seeker.location && seeker.location.streetAddress ? seeker.location.streetAddress : '',
                        city: seeker.location && seeker.location.region ? seeker.location.region : '',
                        state_province_region: seeker.location && seeker.location.region ? seeker.location.region : '',
                        postal_code: seeker.location && seeker.location.postalCode ? seeker.location.postalCode : '',
                        country: seeker.country || '',
                    });
                } else {
                    // Only push relevant fields to the data array
                    data.push({
                        firstName: seeker.firstName || '',
                        lastName: seeker.lastName || '',
                        email: seeker.email || '',
                        phone: seeker.phone || '',
                        country: seeker.country || '',
                    });
                }
            });

            // Generate workbook and add the sheet
            const ws = XLSX.utils.json_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Seekers');

            // Define file name
            const now = moment().format('DD_MM_YYYY_HH_mm');
            const fileName = `nafasi_paid_complete_signups_${now}.xlsx`;

            // Write workbook to file (for Node.js) - adjust the path as needed for your environment
            dispatch({ type: EXTRACT_DATA_SUCCESSFUL });
            XLSX.writeFile(wb, fileName);

            message.success(`Excel file has been created: ${fileName}`);
        } catch (error) {
            message.error("Error fetching documents or generating Excel:", error);
            dispatch({ type: EXTRACT_DATA_FAILED });
        }
    }
}

export const extractPaidAccounts = ({ email }) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EXTRACT_DATA });
            let data = [];
            const transRef = firebase.firestore().collection('subscriptionTransactions').where("status", "==", true);
            const transSnapshot = await transRef.orderBy('paymentDate', 'desc').get();

            if (transSnapshot.empty) {
                message.info('No paid seekers.');
                dispatch({ type: EXTRACT_DATA_FAILED });
                return;
            }

            // Use Promise.all to handle all async operations
            const promises = transSnapshot.docs.map(async doc => {
                let transaction = doc.data();
                const userID = transaction.userID;
                const seekerDoc = await firebase.firestore().collection('seekers').doc(userID).get();

                if (!seekerDoc.exists) {
                    return null;
                }

                let seconds = transaction.paymentDate.seconds || transaction.paymentDate._seconds;
                const unixTime = moment.unix(seconds);
                const paymentTime = unixTime.format('DD MMM YYYY | HH:mm:ss');

                const seeker = seekerDoc.data();
                let dueDateSeconds = seeker.dueDate.seconds || seeker.dueDate._seconds;
                const dueDateUnixTime = moment.unix(dueDateSeconds);
                const dueDate = dueDateUnixTime.format('DD MMM YYYY | HH:mm:ss');

                if (email) {
                    return {
                        email: seeker.email || '',
                        first_name: seeker.firstName || '',
                        last_name: seeker.lastName || '',
                        address_line_1: seeker.location && seeker.location.streetAddress ? seeker.location.streetAddress : '',
                        address_line_2: seeker.location && seeker.location.streetAddress ? seeker.location.streetAddress : '',
                        city: seeker.location && seeker.location.region ? seeker.location.region : '',
                        state_province_region: seeker.location && seeker.location.region ? seeker.location.region : '',
                        postal_code: seeker.location && seeker.location.postalCode ? seeker.location.postalCode : '',
                        country: seeker.country || '',
                        paymentTime: paymentTime || '',
                        packageIndex: seeker.packageIndex ? seeker.packageIndex : '',
                        dueDate: dueDate || ''
                    };
                } else {
                    return {
                        firstName: seeker.firstName || '',
                        lastName: seeker.lastName || '',
                        email: seeker.email || '',
                        phone: seeker.phone || '',
                        country: seeker.country || '',
                        paymentTime: paymentTime || '',
                        packageIndex: seeker.packageIndex ? seeker.packageIndex : '',
                        dueDate: dueDate || ''
                    };
                }
            });

            // Wait for all promises to resolve
            const results = await Promise.all(promises);
            
            // Filter out null values and add valid results to data array
            data = results.filter(result => result !== null);

            if (data.length === 0) {
                message.info('No valid paid seekers found.');
                dispatch({ type: EXTRACT_DATA_FAILED });
                return;
            }

            // Generate workbook and add the sheet
            const ws = XLSX.utils.json_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Seekers');

            // Define file name
            const now = moment().format('DD_MM_YYYY_HH_mm');
            const fileName = `nafasi_paid_accounts_${now}.xlsx`;

            // Write workbook to file
            dispatch({ type: EXTRACT_DATA_SUCCESSFUL });
            XLSX.writeFile(wb, fileName);

            message.success(`Excel file has been created: ${fileName}`);
        } catch (error) {
            message.error("Error fetching documents or generating Excel: " + error.message);
            console.error(error);
            dispatch({ type: EXTRACT_DATA_FAILED });
        }
    }
}

export const extractUnPaidCompleteSignUps = ({ email }) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EXTRACT_DATA });
            const seekersRef = firebase.firestore().collection('seekers');
            const snapshot = await seekersRef.where('complete', '==', true).where('packageIndex', '==', 3).get();

            if (snapshot.empty) {
                message.info('No unpaid and complete seeker.');
                dispatch({ type: EXTRACT_DATA_FAILED });
                return;
            }

            let data = [];
            snapshot.forEach(doc => {
                let seeker = doc.data();
                if (email) {
                    // Only push relevant fields to the data array
                    data.push({
                        email: seeker.email || '',
                        first_name: seeker.firstName || '',
                        last_name: seeker.lastName || '',
                        address_line_1: seeker.location && seeker.location.streetAddress ? seeker.location.streetAddress : '',
                        address_line_2: seeker.location && seeker.location.streetAddress ? seeker.location.streetAddress : '',
                        city: seeker.location && seeker.location.region ? seeker.location.region : '',
                        state_province_region: seeker.location && seeker.location.region ? seeker.location.region : '',
                        postal_code: seeker.location && seeker.location.postalCode ? seeker.location.postalCode : '',
                        country: seeker.country || '',
                    });
                } else {
                    // Only push relevant fields to the data array
                    data.push({
                        firstName: seeker.firstName || '',
                        lastName: seeker.lastName || '',
                        email: seeker.email || '',
                        phone: seeker.phone || '',
                        country: seeker.country || '',
                    });
                }
            });

            // Generate workbook and add the sheet
            const ws = XLSX.utils.json_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Seekers');

            // Define file name
            const now = moment().format('DD_MM_YYYY_HH_mm');
            const fileName = `nafasi_unpaid_complete_signups_${now}.xlsx`;

            // Write workbook to file (for Node.js) - adjust the path as needed for your environment
            dispatch({ type: EXTRACT_DATA_SUCCESSFUL });
            XLSX.writeFile(wb, fileName);

            message.success(`Excel file has been created: ${fileName}`);
        } catch (error) {
            message.error("Error fetching documents or generating Excel:", error);
            dispatch({ type: EXTRACT_DATA_FAILED });
        }
    }
}

export const extractCompleteSignUps = ({ email }) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EXTRACT_DATA });
            const seekersRef = firebase.firestore().collection('seekers');
            const snapshot = await seekersRef.where('complete', '==', true).get();

            if (snapshot.empty) {
                message.info('No complete sign ups.');
                dispatch({ type: EXTRACT_DATA_FAILED });
                return;
            }

            let data = [];
            snapshot.forEach(doc => {
                let seeker = doc.data();
                if (email) {
                    // Only push relevant fields to the data array
                    data.push({
                        email: seeker.email || '',
                        first_name: seeker.firstName || '',
                        last_name: seeker.lastName || '',
                        address_line_1: seeker.location && seeker.location.streetAddress ? seeker.location.streetAddress : '',
                        address_line_2: seeker.location && seeker.location.streetAddress ? seeker.location.streetAddress : '',
                        city: seeker.location && seeker.location.region ? seeker.location.region : '',
                        state_province_region: seeker.location && seeker.location.region ? seeker.location.region : '',
                        postal_code: seeker.location && seeker.location.postalCode ? seeker.location.postalCode : '',
                        country: seeker.country || '',
                    });
                } else {
                    // Only push relevant fields to the data array
                    data.push({
                        firstName: seeker.firstName || '',
                        lastName: seeker.lastName || '',
                        email: seeker.email || '',
                        phone: seeker.phone || '',
                        country: seeker.country || '',
                    });
                }
            });

            // Generate workbook and add the sheet
            const ws = XLSX.utils.json_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Seekers');

            // Define file name
            const now = moment().format('DD_MM_YYYY_HH_mm');
            const fileName = `nafasi_complete_signups_${now}.xlsx`;

            // Write workbook to file (for Node.js) - adjust the path as needed for your environment
            dispatch({ type: EXTRACT_DATA_SUCCESSFUL });
            XLSX.writeFile(wb, fileName);

            message.success(`Excel file has been created: ${fileName}`);
        } catch (error) {
            message.error("Error fetching documents or generating Excel:", error);
            dispatch({ type: EXTRACT_DATA_FAILED });
        }
    }
}

export const extractLastLogins = ({ email }) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EXTRACT_DATA });

            const thirtyDaysAgo = new Date();
            thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

            const seekersRef = firebase.firestore().collection('seekers');
            const snapshot = await seekersRef.where('lastLogin', '<', thirtyDaysAgo).get();


            if (snapshot.empty) {
                message.info('No inactive users.');
                dispatch({ type: EXTRACT_DATA_FAILED });
                return;
            }

            let data = [];
            snapshot.forEach(doc => {
                let seeker = doc.data();

                // Convert Firestore Timestamp to Date String for Excel
                let lastLogin;
                const date = seeker.lastLogin ? seeker.lastLogin.toDate() : 'N/A';
                if (date === 'N/A') {
                    lastLogin = 'N/A'
                } else {
                    lastLogin = moment(date).format("DD-MM-YYYY");
                }


                if (email) {
                    // Only push relevant fields to the data array
                    data.push({
                        email: seeker.email || '',
                        first_name: seeker.firstName || '',
                        last_name: seeker.lastName || '',
                        address_line_1: seeker.location && seeker.location.streetAddress ? seeker.location.streetAddress : '',
                        address_line_2: seeker.location && seeker.location.streetAddress ? seeker.location.streetAddress : '',
                        city: seeker.location && seeker.location.region ? seeker.location.region : '',
                        state_province_region: seeker.location && seeker.location.region ? seeker.location.region : '',
                        postal_code: seeker.location && seeker.location.postalCode ? seeker.location.postalCode : '',
                        country: seeker.country || '',
                        lastLogin
                    });
                } else {
                    // Only push relevant fields to the data array
                    data.push({
                        firstName: seeker.firstName || '',
                        lastName: seeker.lastName || '',
                        email: seeker.email || '',
                        phone: seeker.phone || '',
                        country: seeker.country || '',
                        lastLogin
                    });
                }
            });

            // Generate workbook and add the sheet
            const ws = XLSX.utils.json_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Seekers');

            // Define file name
            const now = moment().format('DD_MM_YYYY_HH_mm');
            const fileName = `nafasi_inactive_users_${now}.xlsx`;

            // Write workbook to file (for Node.js) - adjust the path as needed for your environment
            dispatch({ type: EXTRACT_DATA_SUCCESSFUL });
            XLSX.writeFile(wb, fileName);

            message.success(`Excel file has been created: ${fileName}`);
        } catch (error) {
            message.error("Error fetching documents or generating Excel:", error);
            dispatch({ type: EXTRACT_DATA_FAILED });
        }
    }
}

export const extractAllPaymentAttempts = ({ email }) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EXTRACT_DATA });
            let data = [];
            const transRef = firebase.firestore().collection('subscriptionTransactions');
            const transSnapshot = await transRef.orderBy('paymentDate', 'desc').get();

            if (transSnapshot.empty) {
                message.info('No payment attempts.');
                dispatch({ type: EXTRACT_DATA_FAILED });
                return;
            }

            // Use Promise.all to handle all async operations
            const promises = transSnapshot.docs.map(async doc => {
                try {
                    let transaction = doc.data();
                    const userID = transaction.userID;
                    const seekerDoc = await firebase.firestore().collection('seekers').doc(userID).get();

                    if (!seekerDoc.exists) {
                        return null;
                    }

                    // Safely handle payment date
                    let paymentTime = '';
                    if (transaction.paymentDate) {
                        const seconds = transaction.paymentDate.seconds || transaction.paymentDate._seconds;
                        if (seconds) {
                            const unixTime = moment.unix(seconds);
                            paymentTime = unixTime.format('DD MMM YYYY | HH:mm:ss');
                        }
                    }

                    const seeker = seekerDoc.data();
                    
                    // Safely handle due date
                    let dueDate = '';
                    if (seeker.dueDate) {
                        const dueDateSeconds = seeker.dueDate.seconds || seeker.dueDate._seconds;
                        if (dueDateSeconds) {
                            const dueDateUnixTime = moment.unix(dueDateSeconds);
                            dueDate = dueDateUnixTime.format('DD MMM YYYY | HH:mm:ss');
                        }
                    }

                    if (email) {
                        return {
                            email: seeker.email || '',
                            first_name: seeker.firstName || '',
                            last_name: seeker.lastName || '',
                            address_line_1: seeker.location && seeker.location.streetAddress ? seeker.location.streetAddress : '',
                            address_line_2: seeker.location && seeker.location.streetAddress ? seeker.location.streetAddress : '',
                            city: seeker.location && seeker.location.region ? seeker.location.region : '',
                            state_province_region: seeker.location && seeker.location.region ? seeker.location.region : '',
                            postal_code: seeker.location && seeker.location.postalCode ? seeker.location.postalCode : '',
                            country: seeker.country || '',
                            paymentTime,
                            packageIndex: seeker.packageIndex ? seeker.packageIndex : '',
                            dueDate,
                            phoneNumber: transaction.phoneNumber || '',
                            reference: transaction.reference || '',
                            transactionID: transaction.transactionID || '',
                            status: transaction.status || ''
                        };
                    } else {
                        return {
                            firstName: seeker.firstName || '',
                            lastName: seeker.lastName || '',
                            email: seeker.email || '',
                            phone: seeker.phone || '',
                            country: seeker.country || '',
                            paymentTime,
                            packageIndex: seeker.packageIndex ? seeker.packageIndex : '',
                            dueDate,
                            phoneNumber: transaction.phoneNumber || '',
                            reference: transaction.reference || '',
                            transactionID: transaction.transactionID || '',
                            status: transaction.status || ''
                        };
                    }
                } catch (err) {
                    console.error('Error processing document:', err);
                    return null;
                }
            });

            // Wait for all promises to resolve
            const results = await Promise.all(promises);
            
            // Filter out null values and add valid results to data array
            data = results.filter(result => result !== null);

            if (data.length === 0) {
                message.info('No valid payment attempts found.');
                dispatch({ type: EXTRACT_DATA_FAILED });
                return;
            }

            // Generate workbook and add the sheet
            const ws = XLSX.utils.json_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Seekers');

            // Define file name
            const now = moment().format('DD_MM_YYYY_HH_mm');
            const fileName = `nafasi_all_payment_attempts_${now}.xlsx`;

            // Write workbook to file
            dispatch({ type: EXTRACT_DATA_SUCCESSFUL });
            XLSX.writeFile(wb, fileName);

            message.success(`Excel file has been created: ${fileName}`);
        } catch (error) {
            message.error("Error fetching documents or generating Excel: " + error.message);
            console.error(error);
            dispatch({ type: EXTRACT_DATA_FAILED });
        }
    }
}

export const extractAllSignUps = ({ email, plus }) => {
    return async (dispatch) => {
        try {
            dispatch({ type: EXTRACT_DATA });
            const seekersRef = firebase.firestore().collection('seekers');
            const snapshot = await seekersRef.get();

            if (snapshot.empty) {
                message.info('No sign ups.');
                dispatch({ type: EXTRACT_DATA_FAILED });
                return;
            }

            let data = [];
            snapshot.forEach(doc => {
                let seeker = doc.data();
                const phone = convertPhoneNumber({phoneNumber: seeker.phone || '', plus, country: seeker.country || ''  });


                if (email) {
                    // Only push relevant fields to the data array
                    data.push({
                        email: seeker.email || '',
                        first_name: seeker.firstName || '',
                        last_name: seeker.lastName || '',
                        address_line_1: seeker.location && seeker.location.streetAddress ? seeker.location.streetAddress : '',
                        address_line_2: seeker.location && seeker.location.streetAddress ? seeker.location.streetAddress : '',
                        city: seeker.location && seeker.location.region ? seeker.location.region : '',
                        state_province_region: seeker.location && seeker.location.region ? seeker.location.region : '',
                        postal_code: seeker.location && seeker.location.postalCode ? seeker.location.postalCode : '',
                        country: seeker.country || '',
                        phone
                    });
                } else {
                    // Only push relevant fields to the data array
                    data.push({
                        firstName: seeker.firstName || '',
                        lastName: seeker.lastName || '',
                        email: seeker.email || '',
                        phone: seeker.phone || '',
                        country: seeker.country || '',
                    });
                }
            });

            // Generate workbook and add the sheet
            const ws = XLSX.utils.json_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Seekers');

            // Define file name
            const now = moment().format('DD_MM_YYYY_HH_mm');
            const fileName = `nafasi_all_signups_${now}.xlsx`;

            // Write workbook to file (for Node.js) - adjust the path as needed for your environment
            dispatch({ type: EXTRACT_DATA_SUCCESSFUL });
            XLSX.writeFile(wb, fileName);

            message.success(`Excel file has been created: ${fileName}`);
        } catch (error) {
            message.error("Error fetching documents or generating Excel:", error);
            dispatch({ type: EXTRACT_DATA_FAILED });
        }
    }
}


const convertPhoneNumber = ({phoneNumber, plus, country}) => {
    // Check if the string starts with '0'
    if (phoneNumber.startsWith('0')) {
        // Replace the '0' with '+255'
        switch (country) {
            case 'TZ':
                return plus ? '+255' + phoneNumber.substring(1) : '255' + phoneNumber.substring(1);
            case 'NG':
                return plus ? '+234' + phoneNumber.substring(1) : '234' + phoneNumber.substring(1);
            case 'US':
                return plus ? '+1' + phoneNumber.substring(1) : '1' + phoneNumber.substring(1);
            case 'TR':
                return plus ? '+90' + phoneNumber.substring(1) : '90' + phoneNumber.substring(1);
            case 'SA':
                return plus ? '+966' + phoneNumber.substring(1) : '966' + phoneNumber.substring(1);
            case 'PL':
                return plus ? '+48' + phoneNumber.substring(1) : '48' + phoneNumber.substring(1);
            case 'OM':
                return plus ? '+968' + phoneNumber.substring(1) : '968' + phoneNumber.substring(1);
            case 'KM':
                return plus ? '+269' + phoneNumber.substring(1) : '269' + phoneNumber.substring(1);
            case 'KE':
                return plus ? '+254' + phoneNumber.substring(1) : '254' + phoneNumber.substring(1);
            case 'JO':
                return plus ? '+962' + phoneNumber.substring(1) : '962' + phoneNumber.substring(1);
            case 'IN':
                return plus ? '+91' + phoneNumber.substring(1) : '91' + phoneNumber.substring(1);
            case 'GB':
                return plus ? '+44' + phoneNumber.substring(1) : '44' + phoneNumber.substring(1);
            case 'ES':
                return plus ? '+34' + phoneNumber.substring(1) : '34' + phoneNumber.substring(1);
            case 'BI':
                return plus ? '+257' + phoneNumber.substring(1) : '257' + phoneNumber.substring(1);
            case 'AE':
                return plus ? '+971' + phoneNumber.substring(1) : '971' + phoneNumber.substring(1);
            default:
                return plus ? '+255' + phoneNumber.substring(1) : '255' + phoneNumber.substring(1);
        }


    } else {
        // Return the original string if it doesn't start with '0'
        switch (country) {
            case 'TZ':
                return plus ? '+255' + phoneNumber : '255' + phoneNumber;
            case 'NG':
                return plus ? '+234' + phoneNumber : '234' + phoneNumber;
            case 'US':
                return plus ? '+1' + phoneNumber : '1' + phoneNumber;
            case 'TR':
                return plus ? '+90' + phoneNumber : '90' + phoneNumber;
            case 'SA':
                return plus ? '+966' + phoneNumber : '966' + phoneNumber;
            case 'PL':
                return plus ? '+48' + phoneNumber : '48' + phoneNumber;
            case 'OM':
                return plus ? '+968' + phoneNumber : '968' + phoneNumber;
            case 'KM':
                return plus ? '+269' + phoneNumber : '269' + phoneNumber;
            case 'KE':
                return plus ? '+254' + phoneNumber : '254' + phoneNumber;
            case 'JO':
                return plus ? '+962' + phoneNumber : '962' + phoneNumber;
            case 'IN':
                return plus ? '+91' + phoneNumber : '91' + phoneNumber;
            case 'GB':
                return plus ? '+44' + phoneNumber : '44' + phoneNumber;
            case 'ES':
                return plus ? '+34' + phoneNumber : '34' + phoneNumber;
            case 'BI':
                return plus ? '+257' + phoneNumber : '257' + phoneNumber;
            case 'AE':
                return plus ? '+971' + phoneNumber : '971' + phoneNumber;
            default:
                return plus ? '+255' + phoneNumber : '255' + phoneNumber;
        }
    }
}



//---------------------------------------------------------

export const fetchFeedback = () => {
    return(dispatch) => {
        dispatch({ type: FETCH_FEEDBACK });
        let store = [];
        firebase.firestore().collection('feedback')
            .where('consent', 'in', [true, false]).limit(50)// Filter documents where "consent" is either true or false
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // Access the data of each document
                    const feedback = doc.data();
                    store.push(feedback);
                });

                dispatch({
                    type: FETCH_FEEDBACK_SUCCESSFUL,
                    payload: store
                });
            }, function (error) {
                dispatch({ type: FETCH_FEEDBACK_FAILED });
                console.error('Error getting messages: ', error);
            });
    }
}

export const fetchRecruitersRequests = () => {
    return(dispatch) => {
        dispatch({ type: FETCH_RECRUITERS_REQUESTS });
        let store = [];
        firebase.firestore().collection('adminBucket')
            .orderBy('verified')// Filter documents where "consent" is either true or false
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // Access the data of each document
                    const recruiter = doc.data();
                    store.push(recruiter);
                });

                dispatch({
                    type: FETCH_RECRUITERS_REQUESTS_SUCCESSFUL,
                    payload: store
                });
            })
            .catch(function (error) {
                dispatch({ type: FETCH_RECRUITERS_REQUESTS_FAILED });
                console.error('Error getting admins: ', error);
            })
    }
}

export const updateVerification = ({ recruiter, verified }) => {
    console.log({recruiter: recruiter.userID, verified});
    return async () => {
        try {
            const { email, firstName, companyName, userID } = recruiter;

            const company = companyName;
            const templateId = "d-bfe2c7b59625485aa02696db9fceb767";
            let verificationTime = false;
            if ("verificationTimeStamp" in recruiter) {
                verificationTime = true;
            }

            const response = await fetch("https://us-central1-nafasiio.cloudfunctions.net/updateVerification", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email, firstName, company, userID, verified, verificationTime, templateId
                }),
            });

            if (response.ok) {
                //message.success("Email sent successful to candidate!");
            } else {
                //message.info("Interview offer is sent, however, could not send candidate an email");
            }
        } catch (error) {
            console.error("Error calling function:", error);
        } finally {
            //message.success("Email sent successful to candidate!");
        }
    }
}

export const fetchAssessment = () => {
    return(dispatch) => {
        dispatch({ type: FETCH_ASSESSMENT });
        let store = [];
        firebase.firestore().collection('assessments')
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    // Access the data of each document
                    const assessment = doc.data();
                    store.push({...assessment, assessmentID: doc.id});
                });

                dispatch({
                    type: FETCH_ASSESSMENT_SUCCESSFUL,
                    payload: store
                });
            }, function (error) {
                dispatch({ type: FETCH_ASSESSMENT_FAILED });
                console.error('Error getting messages: ', error);
            });
    }
}